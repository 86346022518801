import Vue from 'vue';
import Vuex from 'vuex';
import { authorization, profile, applications, statistics, offers, tasks, templates, landings, domains, servers, vk, resale } from './client/client-states';

Vue.use(Vuex);
export default new Vuex.Store({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    authorization,
    profile,
    applications,
    statistics,
    offers,
    tasks,
    templates,
    landings,
    domains,
    servers,
    vk,
    resale,
  },
});
