import authorizationRoutes from "@/app/client/authorization/authorization-routes";
import profileRoutes from "@/app/client/profiles/profiles-routes";
import applicationsRoutes from "@/app/client/applications/applications-routes";
import statisticsRoutes from "@/app/client/statistics/statistics-routes";
import offersRoutes from "@/app/client/offers/offers-routes";
import tasksRoutes from "@/app/client/tasks/tasks-routes";
import templatesRoutes from "@/app/client/templates/templates-routes";
import landingsRoutes from "@/app/client/landings/landings-routes";
import domainsRoutes from "@/app/client/domains/domains-routes";
import serversRoutes from "@/app/client/servers/servers-routes";
import vkRoutes from "@/app/client/vk/vk-routes";
import resaleRoutes from "@/app/client/resale/resale-routes";

const clientRoutes = [
  ...authorizationRoutes,
  ...profileRoutes,
  ...applicationsRoutes,
  ...statisticsRoutes,
  ...offersRoutes,
  ...tasksRoutes,
  ...templatesRoutes,
  ...landingsRoutes,
  ...domainsRoutes,
  ...serversRoutes,
  ...vkRoutes,
  ...resaleRoutes,
];

export default clientRoutes;
